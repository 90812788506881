import { Component } from 'react';

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import RetinaImage from '../RetinaImage/RetinaImage';
// import BackToTop from '../BackToTop/BackToTop';
// import classes from './Footer.module.scss';


class Footer extends Component {

    render() {

        return null;
    }
}

export default Footer;