import { Component } from 'react';
import {withRouter} from 'react-router-dom';

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import RetinaImage from '../RetinaImage/RetinaImage';
//
// import classes from './PublicHeader.module.scss';

class PublicHeader extends Component {


    render() {

        return null;

        // return (
        //     <header className={classes.header}>
        //         <Container>
        //                             <RetinaImage className={classes.logoImg+" img-fluid"}
        //                                         onClick={this.goHome}
        //                                         width={393}
        //                                         height={38}
        //                                         src={[
        //                                             require("../../assets/images/logos/logos-???.png").default,
        //                                             require("../../assets/images/logos/logos-???@2x.png").default,
        //                                             require("../../assets/images/logos/logos-???@3x.png").default]}
        //                                  alt="????" />
        //         </Container>
        //
        //     </header>
        //
        // );
    }
}

export default withRouter(PublicHeader);